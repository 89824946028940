@import 'variables';

.modal {
    padding-left: 0 !important;

    .modal-dialog {
        .modal-content {
            border-radius: 10px;

            .modal-header {
                .modal-title {
                    color: $primaryColor;
                }
            }

            .modal-body .form-control {
                border: 1px solid $lightNeutral  !important;
            }

            .modal-footer .btn-link {
                color: $mediumNeutral;

                &:hover {
                    color: $primaryColor;
                    box-shadow: none;
                }
            }
        }
    }

    .modal-dialog {
        @media (max-width: 768px) {
            max-width: 96vw;
            margin: 2vh 2vw;
        }
    }
}

.announce-message-modal {
    .alert.alert-primary {
        background: transparent;
        padding: 0;
        width: 100%;

        .user-card {
            background: $clientColor;
            box-shadow: none;
            border: 1px solid $lightNeutral;
            overflow: hidden;
            border-radius: 10px;
            color: $primaryColor;
            width: 100%;
            padding: 10px 16px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .user-avatar {
                height: 50px;
                width: 50px;
                min-width: 50px;
                border-radius: 50%;
                margin-right: 16px;
                overflow: hidden;

                img {
                    min-height: 100%;
                    min-width: 100%;
                    height: auto;
                    width: 100%;
                }
            }
        }
    }
}