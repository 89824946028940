@import 'variables';

.announcer-new-post-btn {
    background: $clientColor;
    color: $primaryColor;
    box-shadow: $boxShadow;
    width: auto;
    display: block;
    padding: 10px 15px 9px 15px !important;
    transition: all 0.3s;

    &:hover {
        color: $clientColor;
        background: $primaryColor;
    }
}

.announcer-post-modal {
    .modal-header {
        border: none;
        padding: 20px 32px;

        .modal-title {
            color: $primaryColor;
        }

        .sponsored-post-tag {
            margin-left: 20px;
        }
    }

    .modal-body {
        padding: 24px 20px;
    }
}

.announcer-info-wrapper {
    padding: 24px 20px 10px 20px;
    background: $clientColor;
    border-radius: 10px;

    .btn-link {
        color: $mediumNeutral;
        height: 36px;
        padding: 8px 18px;
        line-height: normal;
        margin: 0 6px;
    }
}

.announcer-info-wrapper h3,
.announcer-promote-wrapper h3,
.user-profile-wrapper h4 {
    font-size: 21px;
    color: $primaryColor;
    margin-bottom: 15px;
}

.announcer-promote-wrapper {
    padding: 24px;

    h3 {
        margin-bottom: 24px;
    }

    .my-selection {
        @media (max-width: 1400px) {
            margin-top: 32px;
        }
    }
}

.MuiPaper-elevation2.MuiPaper-rounded {
    box-shadow: $boxShadow  !important;
    border-radius: 8px;
}

.MuiToolbar-gutters {
    padding-left: 16px !important;
}

.announcer-tab-content {
    padding: 0 2vw;
    margin-bottom: 0;

    .company-form {
        >div {
            margin-bottom: 12px;
        }
    }

    .preview-img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        background-color: #f5f5f5;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        margin-left: 10px;
        overflow: hidden;
    }

    .preview-img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }


    .timeline {
        .timeline-body {
            border: 1px solid $lightNeutral;
            margin: 20px 5vw;
            width: auto;
        }
    }

    .container {
        max-width: unset;
    }
}

@media (min-width: 1900px) {

    .subscriptions-list>.announcer-subscription-card,
    .active-subscription.announcer-subscription-card {
        width: 25%;
    }
}

@media (min-width: 1400px) and (max-width: 1899px) {

    .subscriptions-list>.announcer-subscription-card,
    .active-subscription.announcer-subscription-card {
        width: 33%;
    }
}

@media (min-width: 993px) {
    .announcer-profile {
        background-position: bottom 4% right 2vw;
        background-repeat: no-repeat;
        background-size: 8vw;
        background-image: url('../assets/img/gear_theodolite.png');
    }
}

@media (min-width: 650px) and (max-width: 1399px) {

    .subscriptions-list>.announcer-subscription-card,
    .active-subscription.announcer-subscription-card {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .announcer-tab-content {
        padding: 0 1vw;

        .timeline {
            .timeline-body {
                margin: 20px 2vw;
            }
        }
    }

    .announcer-promote-wrapper {
        .MuiToolbar-root {
            flex-direction: column;
            gap: 10px;
        }
    }
}

.announcer-subscription-card {

    .card {
        border-radius: 10px;
        transition: all 0.3s;
        box-shadow: $boxShadow;
        min-height: 310px;
        height: 95%;
        display: flex;
        flex-direction: column;

        .card-header {
            border-radius: 10px 10px 0 0;
            background: none;
            border: none;
            padding: 20px 20px 0 20px;
            color: $primaryColor;
            font-size: 18px;
        }

        .card-body {
            padding: 10px 20px 30px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .card-text {
                margin-bottom: 8px;
            }

            .text-muted.text-sm-left {
                margin: 20px 0 0 0;
                line-height: 1.2;
            }
        }

        .blue-btn {
            width: calc(100% - 40px);
            justify-content: center;
            margin: 10px 0 0 0;
        }
    }

    .card.border-info {
        border: none;
        background: rgba(255, 255, 255, 0.7);

        &:hover {
            background: $clientColor;
        }
    }

    .card.border-selected {
        border: 1px solid $primaryContrast;

        .card-header {
            color: $primaryContrast;
        }
    }
}

.announcer-subscription-1year-card {
    margin-right: 20px;

    .card {
        border-radius: 10px;
        transition: all 0.3s;
        box-shadow: $boxShadow;
        min-height: 290px;
        height: 95%;

        .card-header {
            border-radius: 10px 10px 0 0;
            background: none;
            border: none;
            padding: 20px 20px 0 20px;
            color: $primaryColor;
            font-size: 18px;
        }
    }

    .card.border-info {
        border: none;
        background: rgba(255, 255, 255, 0.7);

        &:hover {
            background: $clientColor;
        }
    }

    .card.border-selected {
        border: 1px solid $primaryContrast;

        .card-header {
            color: $primaryContrast;
        }
    }
}

.announcer-subscription-card+.input-group {
    margin: 20px 0 20px 6px;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    gap: 20px;
}

.announcer-modal {
    overflow: hidden;
    padding-left: 0 !important;

    .modal-dialog {
        margin-left: unset;
        margin-right: unset;
        max-width: 100vw;
        display: flex;
        justify-content: center;
    }

    .modal-content {
        width: 90vw;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 95vh;

        @media (min-width: 1200px) {
            width: 70vw;
        }

        .modal-body {
            form {
                >div {
                    margin: 0 0 12px;

                    >.col-lg-4 {
                        margin: 0 0 12px;

                        @media (max-width: 992px) {
                            padding: 0;
                        }
                    }

                    >.col-lg-4:first-child {
                        padding-left: 0;
                    }

                    >.col-lg-4:last-child {
                        padding-right: 0;
                    }
                }

                .btn {
                    max-width: fit-content;
                }
            }
        }
    }
}

.announcer-library {
    .library-document-card {
        .document-info {
            .row {
                display: flex;
                flex-wrap: nowrap;

                >* {
                    width: fit-content;
                }
            }

            >.row {
                max-width: 100%;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                margin: 0 0 10px;
            }
        }
    }
}

.payment-wrapper {
    padding: 20px 4vw !important;
    height: calc(100vh - 100px);
    overflow: hidden;

    h4 {
        color: $clientColor;
        font-size: 24px;
        margin-bottom: 20px;
    }

    .stripe-form-wrapper {
        border-radius: 10px;
        width: 100%;
        height: calc(100vh - 190px);
        background: $transparentBackground;
        padding: 25px;
        overflow-y: auto;
        overflow-x: hidden;

        form {
            max-width: 400px;

            input {
                font-family: 'Roboto', "Helvetica Neue", Helvetica, Arial, sans-serif;
            }
        }

        .blue-btn {
            margin: 16px 0 0;
        }

        .payment-subscription-name {
            font-size: 16px;
            color: $primaryColor;
            font-weight: 600;
            margin-bottom: 8px;
        }

        .payment-total-price {
            color: $textColor;
        }
    }
}

.add-employee,
.alert.alert-primary.add-employee {
    background: $clientColor;
    border: 1px solid $lightNeutral;
    box-shadow: $boxShadow;
    padding: 10px 16px;

    .add-employee-card {
        align-items: center;

        >div {
            align-items: center;

            .employee-avatar {
                aspect-ratio: 1/1;
                object-fit: cover;
                border-radius: 50px;
            }

            a {
                color: $primaryColor;
                font-weight: 500;
            }
        }
    }
}