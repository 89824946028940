@import 'variables';

.lock-image {
    background-image: url("../assets/img/login-background.jpg");
    background-size: cover;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.register.register-with-news-feed {
    .news-caption {
        padding: 20px;
        color: #fff;

        p {
            margin-bottom: 0;
        }
    }

    .news-image img {
        position: unset;
        min-width: unset;
        margin: unset;
    }
}

.right-content {
    height: 100vh;
    background: rgba(13, 13, 13, 0.8);
    backdrop-filter: blur(3px);
    display: flex;
    flex-flow: column;
    justify-content: flex-end;

    .logo-div {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 40px;
        gap: 12px;

        img {
            height: 64px;
            width: 64px;
        }
    }

    h1 {
        color: $lightNeutral;
        text-align: center;
        font-size: 48px;
        font-weight: 400;
    }

    .register-content .control-label,
    .register-content label {
        color: $clientColor;
    }

    .register-content .control-label {
        margin: 12px 0 4px 0;
        font-weight: 500;
    }

    .register-content label {
        font-weight: 400;
    }


    .register-buttons .btn {
        margin: 0 0 10px 0;
        width: 100%;
    }

    .text-center,
    .text-light {
        color: rgba(255, 255, 255, 0.8);
    }

    a {
        color: $primaryContrast;
    }
}

.rien {
    visibility: hidden;
    margin-top: 10px;
    margin-left: 15px
}

.alertInfos {
    visibility: visible;
    color: $primaryContrast;
    margin-top: 10px;
    margin-left: 15px
}