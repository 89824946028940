@import 'variables';

.profile-content.container.cgv {
    background: $transparentBackground;
    width: 68vw;
    max-width: 68vw;
    height: calc(100vh - 132px);
    margin: 80px 16vw 20px 16vw;
    overflow-x: hidden;
    overflow-y: auto;
    color: $textColor;

    h1,
    h4 {
        color: $primaryColor;
    }

    h4:not(:first-child) {
        margin-top: 20px;
    }

    p {
        margin-bottom: 8px;
    }

    @media (max-width: 768px) {
        width: 92vw;
        max-width: 92vw;
        height: calc(100vh - 154px);
        margin: 80px 4vw 20px 4vw;

        h1 {
            font-size: 24px;
        }
    }
}