@import 'variables';

.form-control {
    border-radius: 5px !important;
    border: 1px solid $lightNeutral;
    font-weight: 300 !important;
    color: $textColor;
}

label.form-label {
    font-size: 14px;
    margin-bottom: 4px;
    color: $textColor;
    padding: 0;
    font-weight: 400;

    span {
        font-weight: 400;
    }
}

form>.row {
    margin-bottom: 10px;
    justify-content: flex-start;
}

.react-checkbox-tree {
    .rct-checkbox {
        color: $mediumNeutral;

        .rct-icon-check {
            color: $primaryColor;
        }
    }

    .rct-title {
        font-size: 15px;
        font-weight: 400;
    }

    label:hover {
        background: none;
        color: $primaryColor;
    }
}

.checkbox.checkbox-css {

    label::after,
    label::before {
        top: 6px;
        border-radius: 2px;
    }

    label::before {
        background: $lightNeutral;
        transition: border 0.2s;
    }

    label::after {
        background: $primaryContrast;
    }

    &:hover {
        label::before {
            border: 2px solid $primaryContrast;
        }
    }
}

.react-search-field {
    border: 1px solid $lightNeutral  !important;
    height: 32px !important;

    .react-search-field-input {
        height: 100% !important;
        color: $mediumNeutral;
        border-radius: 5px;
        font-weight: 300 !important;
    }

    .react-search-field-button {
        height: 100% !important;
        width: 31px !important;
        border-left: 0 !important;
        border-radius: 0 5px 5px 0;

        svg {
            fill: $mediumNeutral  !important;
            width: 16px;
            height: 16px;
        }
    }
}

.PhoneInputInput {
    border: 1px solid $lightNeutral;
    border-radius: 5px;
    padding: 6px 12px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input:not(textarea),
.ant-input-affix-wrapper,
.ant-picker {
    border-radius: 32px;

    &:hover,
    &:focus,
    &:active {
        border-color: $primaryContrast  !important;
    }
}

.ant-select-focused:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker-focused {
    border-color: $primaryContrast  !important;
}

textarea.ant-input {
    border-radius: 8px;

    &:hover,
    &:focus,
    &:active {
        border-color: $primaryContrast;
    }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: $clientColor;
    background: $primaryContrast;
}

.ant-picker-header-view button:hover {
    color: $primaryColor;
}

.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    padding-left: 12px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: rgba(1, 0, 185, 0.1);

    .ant-select-item-option-state {
        color: $primaryColor;
    }
}