@import 'variables';

.library-wrapper {
    padding: 20px 4vw;
    height: calc(100vh - 100px);
    overflow: hidden;

    h4 {
        color: $clientColor;
        font-size: 24px;
        margin-bottom: 20px;
    }

    .library-content-wrapper {
        border-radius: 10px;
        width: 100%;
        height: calc(100vh - 190px);
        background: $transparentBackground;
        padding: 25px;
        overflow-y: auto;
        overflow-x: hidden;

        .row:first-child {
            margin-bottom: 16px;
            align-items: center;

            .col {
                .react-search-field {
                    margin-bottom: 8px;
                }

                .blue-btn {
                    margin-bottom: 4px;
                }
            }
        }
    }
}

.announce-card {
    border-radius: 10px;
    overflow: hidden;
    height: 100%;

    .media {
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;

        >.media-left {
            padding: 0;
            width: 100%;

            img {
                box-shadow: none;
                width: 100%;
                object-fit: cover;
            }
        }

        >.media-body {
            max-width: 100%;
            padding: 16px;

            h3 {
                font-size: 16px;
                color: $primaryColor;
            }

            .announce-card-meta {
                color: $textColor;
                font-weight: 300;
                font-size: 14px;

                .announce-card-user {
                    font-weight: 500;
                    text-transform: uppercase;
                    color: $textColor;

                    &:hover {
                        color: $primaryColor;
                    }

                    span {
                        text-transform: none;
                        font-weight: 300;
                        margin-left: 4px;
                    }
                }

                .fa-map-marker {
                    color: $primaryColor;
                    margin-right: 8px;
                }
            }

            .row {
                align-items: center;

                p {
                    margin-bottom: 0;
                }

                .announce-card-category {
                    border: 1px solid $primaryColor;
                    display: inline-block;
                    padding: 1px 6px;
                    border-radius: 4px;
                    text-transform: uppercase;
                    color: $primaryColor;
                    font-weight: 500;
                }

                .announce-card-price {
                    text-align: right;
                    color: $primaryColor;
                    font-weight: 500;
                    white-space: nowrap;

                    >span {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

.library-document-card {
    background: $clientColor;
    border-radius: 10px;
    box-shadow: $boxShadow;
    overflow: hidden;
    height: 100%;

    .media {
        flex-flow: column;
        position: relative;
        overflow: hidden;
        width: 100%;

        .media-body {
            // width: 100%;
            min-width: 100%;
            max-height: 200px;
            overflow: hidden;
            object-fit: cover;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .document-info {
            padding: 20px 16px 16px 16px;
            height: fit-content;

            .document-name {
                margin-bottom: 10px;
                font-size: 16px;
                font-weight: 500;
                color: $primaryColor;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .document-description {
                font-size: 13px;
                height: 55px;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $textColor;
            }

            .div:last-child {
                width: 100%;
            }
        }
    }
}

.announce-detail-card,
.announce-detail-user {
    border-radius: 10px;
    overflow: hidden;
}

.announce-detail-card {
    .card-title {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        h4 {
            margin-bottom: 0;
            font-size: 24px;
            color: $primaryColor;
            font-weight: 500;
        }

        p {
            text-align: right;
            color: $primaryColor;
            font-weight: 500;
            white-space: nowrap;
            font-size: 24px;
            margin-bottom: 0;
        }
    }

    .card-body {
        padding: 20px;

        .announce-date,
        .announce-description p {
            font-weight: 300;
            color: $textColor;
        }

        .announce-address {
            color: $primaryColor;
            font-weight: 500;
            margin-bottom: 16px;

            .fa-map-marker {
                margin-right: 8px;
            }
        }

        .row {
            width: 100%;
            justify-content: flex-end;
            align-items: center;

            .rounded-corner.blue-btn {
                width: fit-content;
            }
        }
    }
}

.announce-detail-user {
    .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;

        .user-card {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            box-shadow: $boxShadow;
            border-radius: 10px;
            text-transform: uppercase;
            color: $primaryColor;
            background-color: $clientColor;
            transition: all 0.3s;

            i,
            img {
                margin-right: 16px;
            }

            &:hover {
                background-color: $primaryContrast;
                color: $clientColor;
            }
        }
    }
}

.profile-content.announce-form-wrapper {
    padding: 0 20vw 24px 20vw;

    .user-profile-wrapper {
        margin-top: 0;
    }
}

.library-new {
    .container {
        margin: 24px 20vw;
        width: auto;
        background: $clientColor;
        max-width: unset !important;
        padding: 20px;
        border-radius: 10px;

        h4 {
            color: $primaryColor;
        }

        >.row {
            justify-content: center;

            form {
                >div {
                    margin-top: 16px;

                    .form-label+div>.blue-btn {
                        margin: 0 0 10px 0;
                    }
                }

                >.row {
                    justify-content: flex-end;

                    .btn {
                        max-width: fit-content;
                    }
                }
            }
        }
    }
}

.library-modal {
    .modal-dialog .modal-content .modal-body {
        padding: 16px 0;

        .media-body img {
            min-width: 100%;
            max-width: 100%;
        }

        .text-container {
            padding: 8px 16px 0;
        }
    }
}

@media (min-width: 993px) {
    .library-wrapper {
        padding: 20px 12vw 20px 2vw;
        background-position: bottom 4% right 2vw;
        background-repeat: no-repeat;
        background-size: 8vw;
    }

    .library-wrapper:not(.announces-wrapper):not(.partners-wrapper):not(.addresses-wrapper) {
        background-image: url('../assets/img/gear_schematics.png');
    }

    .announces-wrapper {
        background-image: url('../assets/img/gear_handshake.png');
    }
}

@media (max-width: 992px) {
    .profile-content.announce-form-wrapper {
        padding: 20px;
    }

    .library-new .container {
        margin: 20px;
    }
}

@media (min-width: 768px) {
    .announce-detail-card {
        margin-right: 2vw;
    }
}