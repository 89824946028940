@import 'variables';

.top-menu {
    background: none;
    top: 0;
    left: 200px;
    height: 70px;
    width: fit-content;

    .nav {
        white-space: nowrap;
        display: flex;
        justify-content: space-between;

        > li {
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 16px;

            > a {
                display: block;
                position: relative;
                padding: 0;
                color: $textColor;
                font-size: 15px;
                transition: all 0.3s;

                &:hover, &:focus {
                    color: $primaryColor; 
                }
            }

            > a.active {
                color: $primaryColor;

                &::after {
                    content: '';
                    width: 50px;
                    height: 2px;
                    background: $primaryColor;
                    position: absolute;
                    right: 0;
                    bottom: -5px;
                }
            }
        }
    }
    
    @media (max-width: 1400px) {
        .container {
            padding: 0;
        }
    }
}