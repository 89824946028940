@import 'variables';

body {
    margin: 0;
    font-family: 'Roboto', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: $textColor;
    background: url('../assets//img/fond_2.jpg') no-repeat;
    background-size: cover;
}

body,
#root {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

a,
a:hover {
    text-decoration: none;
}

ul {
    list-style: none;
}

::selection {
    background: $primaryColor;
}

.page-container.page-with-top-menu {
    margin-top: 60px;
    padding: 0;
    min-height: calc(100vh - 60px);
    background-repeat: no-repeat;
}

.library-new {
    max-height: calc(100vh - 60px);
    overflow-y: auto;
}