@import 'variables';

.profile {
    overflow: auto;
    padding: 24px 20vw;

    .profile-content {
        height: fit-content;
        padding: 0;
    }

    .profile-header {
        background: $clientColor;
        border-radius: 10px;
        position: initial;
        padding: 20px 32px 16px 32px;

        .profile-header-content {
            color: $textColor;
            padding: 0;
            height: fit-content;
            position: initial;
            display: flex;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;

            >div {
                margin-bottom: 12px;
            }

            .profile-header-img {
                width: 80px;
                height: 80px;
                background: none;
                border-radius: 50%;
                padding: 0;
                float: unset;
                position: initial;
                margin-right: 32px;
                margin-top: 0;
                z-index: 1;
            }

            .profile-header-name {
                color: $primaryColor;
                font-weight: 300;
            }

            .profile-header-img+.profile-header-info {
                margin-left: 0;

                .profile-header-name {
                    font-weight: 500;
                    font-size: 24px;
                    margin-bottom: 4px;
                }

                .profile-header-job {
                    margin-bottom: 0;
                    font-style: italic;
                }
            }
        }
    }

    .profile-header-tab.nav-tabs {
        width: 100%;
        padding: 0;
        margin: 0;
        padding-top: 12px;

        .nav {
            width: 100%;
            justify-content: center;

            .nav-link {
                background: none;
                color: $textColor  !important;
                font-weight: 500;
                margin: 0;
                padding: 8px 16px;
                transition: all 0.3s ease-out;

                &:hover {
                    background: none;
                    color: $primaryContrast  !important;
                }
            }

            .nav-link.active {
                background: $primaryColor;
                color: $clientColor  !important;
                border-radius: 50px;
                padding: 8px 16px;
            }
        }
    }

    .profile-header.user-header {
        .profile-header-tab.nav-tabs {
            border-top: 1px solid $lightNeutral  !important;
        }
    }

    .user-friend-card {
        background: $clientColor;
        box-shadow: none;
        border: 1px solid $lightNeutral;
        overflow: hidden;
        border-radius: 10px;
        margin: 6px 0;
        color: $primaryColor;
        transition: all 0.3s;
        padding: 2px 0 2px 10px;
        overflow: visible;

        .media {
            display: flex;
            align-items: center;
            justify-content: space-between;

            >.media-left {
                display: flex;
                align-items: center;
                padding: 0;

                .user-avatar {
                    border-radius: 32px;
                    height: 32px;
                    width: 32px;
                    min-width: 32px;
                    overflow: hidden;
                    margin-right: 10px;

                    >img {
                        box-shadow: none;
                    }
                }

                >span {
                    text-transform: uppercase;
                    color: $primaryColor;
                    font-size: 13px;
                    line-height: 1.2;
                }
            }

            .btn-group {
                box-shadow: none;
            }

            .media-body {
                width: fit-content;

                .btn-link.dropdown {
                    margin-left: 0;
                }
            }
        }
    }
}


.profile-header.announcer-header {
    margin-bottom: 20px;

    .profile-header-content {
        padding-bottom: 0;

        .profile-header-img {
            background: $clientColor;
            padding: 0;
        }

        .profile-header-img+.profile-header-info {
            margin-left: 0;
        }

        .subscription-badge,
        .no-subscription-badge {
            font-weight: 500;
            box-shadow: none;
            padding: 4px 8px;
            font-size: 12px;
        }

        .subscription-badge {
            background: $primaryColor  !important;
        }

        .no-subscription-badge {
            background: $mediumNeutral  !important;
        }
    }
}

.profile-header.announcer-header+.profile-content {
    background: $clientColor;
    border-radius: 10px;

    .nav-tabs {
        border: 0;
    }
}

.user-profile-wrapper {
    background: $transparentBackground;
    border-radius: 10px;
    padding: 20px 20px 10px 20px;
    margin-top: 20px;

    .rce-container-citem {
        min-width: 100%;

        .rce-citem {
            background: transparent;
            height: fit-content;
            min-width: unset;
            padding-bottom: 8px;
            border-bottom: 1px solid $lightNeutral;

            .rce-citem-avatar {
                .rce-avatar-container {
                    height: 32px;
                    min-width: 32px;
                    width: 32px;

                    img {
                        height: auto;
                        min-height: 100%;
                    }
                }
            }

            .rce-citem-body {
                border: none;

                .rce-citem-body--top-title {
                    font-size: 14px;
                    text-transform: uppercase;
                    color: $primaryColor;
                    font-weight: 600;
                }

                .rce-citem-body--top-time {
                    color: $mediumNeutral;
                }

                .rce-citem-body--bottom {
                    margin-top: 0;

                    .rce-citem-body--bottom-title {
                        color: $textColor;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .rce-navbar {
        background: transparent;
        border-left: 1px solid $mediumNeutral;

        .friend-message-title {
            padding: 6px 10px 6px 4px;
            display: flex;
            align-items: center;

            >span {
                text-transform: uppercase;
                color: $primaryColor;
                font-weight: 600;
                margin-left: 8px;
                font-size: 16px;
            }

            .rce-avatar-container.large {
                height: 48px;
                min-width: 48px;
                width: 48px;
            }
        }
    }

    .message-wrapper {
        padding: 0;

        .message-list {
            background: none;
            height: fit-content;
            overflow-y: auto;
            border-left: 1px solid $mediumNeutral;
            padding: 20px 10px;

            .rce-mbox:not(.rce-mbox-right) {
                background: $primaryColor;

                .rce-mbox-body>div {
                    color: $clientColor;
                }
            }

            .rce-mbox-left-notch {
                fill: $primaryColor;
            }
        }
    }

    .rce-container-input {
        border-radius: 5px;
        margin-top: 10px;

        .rce-input {
            padding: 0 5px 0 10px;
        }

        .rce-input-buttons {
            .rce-button {
                border-radius: 30px;
                padding: 8px 16px;
                background: $primaryContrast  !important;
                border-color: $primaryContrast  !important;
                color: $clientColor;
                text-transform: uppercase;
                font-weight: 600;
                transition: all 0.3s;

                &:hover {
                    background: $clientColor  !important;
                    color: $primaryContrast  !important;
                    border-color: $primaryContrast  !important;
                }
            }
        }
    }

    .table-profile>:not(:first-child) {
        border-top: 1px solid $mediumNeutral;

        input {
            border: none;
            padding: 7px;
            border-radius: 5px;
        }

        .form-row {
            padding: 0 15px;

            >td {
                padding-top: 7px;
            }
        }

        .react-datepicker {
            border: none;

            .react-datepicker__header {
                background-color: $primaryColor;
                border: none;

                button {
                    background: transparent;
                    border: 0;
                    color: $clientColor;
                }

                .react-datepicker__day-name,
                .react-datepicker__day,
                .react-datepicker__time-name {
                    color: $clientColor;
                }
            }

            .react-datepicker__day--selected,
            .react-datepicker__day--keyboard-selected {
                background-color: $primaryColor;
                border-radius: 5px;
            }

            .react-datepicker__day--today {
                border: 1px solid $primaryColor;
                border-radius: 5px;
            }
        }
    }
}

.table.table-profile>tbody>tr>td:first-child {
    padding: 13px;
}

.profile:last-child {
    height: calc(100vh - 100px);
}

.profile-header .profile-header-cover:before,
.profile-header .profile-header-cover {
    display: none;
}

@media (min-width: 993px) {
    .profile-wrapper {
        background-position: bottom 4% right 2vw;
        background-repeat: no-repeat;
        background-size: 8vw;
        background-image: url('../assets/img/gear_person.png');
    }
}

@media (max-width: 1200px) {
    .profile {
        padding: 20px 10vw;
    }
}

@media (max-width: 992px) {
    .profile {
        padding: 20px 2vw;
    }
}

@media (max-width: 768px) {
    .profile {
        .profile-header {
            padding: 20px 20px 16px 20px;
        }
    }
}

@media (max-width: 512px) {
    .profile:last-child {
        height: calc(100vh - 114px);
    }
}