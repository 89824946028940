@import 'variables';

.library-wrapper .partners-content-wrapper,
.library-wrapper .addresses-content-wrapper {

    .row,
    .row:first-child {
        align-items: flex-start;

        .col {
            padding-bottom: 10px;

            >h5 {
                color: $primaryColor;
                font-size: 16px;
            }

            >.card.partner-card {
                box-shadow: $boxShadow;
                border-radius: 10px;

                .card-img-top {
                    max-height: 160px;
                    object-fit: contain;
                    padding: 16px;
                }

                .card-body {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 16px 12px 16px;

                    .card-title {
                        margin: 12px 8px 12px 0;
                    }
                }
            }

            >.card.address-card {
                height: 95%;
                box-shadow: $boxShadow;
                border-radius: 8px;

                .card-body {
                    color: $textColor;

                    .card-title {
                        color: $primaryColor;
                        font-size: 15px;
                    }

                    .card-subtitle,
                    .card-text {
                        color: $textColor;
                        font-size: 14px;
                    }

                    .card-text {
                        margin-bottom: 8px;
                    }

                    a {
                        color: $mediumNeutral;
                        transition: color 0.3s;
                        font-size: 13px;

                        &:hover {
                            color: $primaryColor;
                        }
                    }
                }
            }
        }
    }
}

.library-wrapper .addresses-content-wrapper {
    >.row:first-child {
        align-items: stretch;
    }
}

@media (min-width: 993px) {
    .partners-wrapper {
        background-image: url('../assets/img/gear_lightbulb.png');
    }

    .addresses-wrapper {
        background-image: url('../assets/img/gear_location.png');
    }
}