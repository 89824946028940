@import 'variables';

.wall-row {
    .profile-content {
        height: calc(100vh - 100px);
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 24px 1vw 24px 16vw;
    }

    @media (min-width: 993px) {
        background-image: url('../assets/img/gear_home.png');
        background-position: bottom 4% left 3vw;
        background-repeat: no-repeat;
        background-size: 8vw;
    }
}

.announcer-card {
    position: fixed;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url('../assets/img/default-announcer-card-background.jpg') no-repeat;
    background-size: cover;
    background-position: 50% 0%;
    border-radius: 20px;
    min-width: 200px;
    box-shadow: $boxShadow;
    top: 80px;
    width: 18vw;
    height: calc(100vh - 150px);
    right: 24px;

    .card-body {
        height: 100%;
        position: relative;
        padding: 25px;
    }

    .card-title {
        font-size: 18px;
        color: $clientColor;
        font-weight: 400;
        width: fit-content;
        padding-bottom: 2px;
        border-bottom: 1px solid $clientColor;
        text-align: right;
        float: right;
    }

    .card-content {
        position: absolute;
        bottom: 25px;
        left: 25px;
        width: calc(100% - 50px);

        .announcer-card-meta {
            display: flex;
            flex-flow: column;
            align-items: flex-start;

            h5 {
                font-size: 20px;
                color: $mediumNeutral;
                margin-bottom: 10px;
            }
        }
    }
}

.suggestion-wrapper {
    position: relative;
    background: $clientColor;
    border-radius: 10px;

    >h4 {
        position: absolute;
        left: -145px;
        top: 10px;
        width: 130px;
        text-align: right;
        color: $clientColor;
        font-size: 24px;
    }

    .suggestion-container {
        padding: 0 8px;
        background-color: $clientColor;
        border-radius: 10px;

        .carousel-container,
        .react-multi-carousel-item {
            background-color: $clientColor;

            .react-multi-carousel-track>.react-multi-carousel-item>a {
                background-color: $clientColor;
                padding: 4px 0;
            }

            .react-multi-carousel-dot-list {
                display: none;
            }

            .react-multiple-carousel__arrow {
                background: rgba(255, 255, 255, 0.2);

                &::before {
                    color: $primaryColor;
                }
            }

            .react-multiple-carousel__arrow--left {
                left: 0;
            }

            .react-multiple-carousel__arrow--right {
                right: 0;
            }
        }

        .suggestion-card {
            background: $clientColor;
            box-shadow: none;
            border: 1px solid $lightNeutral;
            overflow: hidden;
            border-radius: 10px;
            margin: 6px;
            color: $primaryColor;
            transition: all 0.3s;
            min-width: fit-content;

            .card-body {
                width: 100%;
                min-width: fit-content;
                height: 100%;
                padding: 10px 16px;
                display: flex;
                align-items: center;

                .card-title {
                    margin: 0;
                }

                .user-avatar {
                    height: 50px;
                    width: 50px;
                    min-width: 50px;
                    border-radius: 50%;
                    margin-right: 16px;
                    overflow: hidden;

                    img {
                        min-height: 100%;
                        min-width: 100%;
                    }
                }
            }

            &:hover {
                background: $primaryContrast;
                color: $clientColor;
            }
        }
    }
}

.complete-profile-card,
.loading-card {
    background: $clientColor;
    border-radius: 10px;
}

.timeline {
    &::before {
        display: none;
    }

    .timeline-icon {
        display: none;

        span {
            display: none;
        }
    }

    .timeline-time {
        display: none;
    }

    >li {
        position: relative;
        min-height: 50px;
        padding: 0;
        margin-bottom: 24px;
    }

    .timeline-body {
        width: 100%;
        margin-left: 0;
        background: $clientColor;
        position: relative;
        padding: 16px 20px;
        border-radius: 10px;

        .timeline-comment-box {
            background: transparent;
            margin: 0 -20px;
            border-radius: 0 0 10px 10px;

            .user img {
                width: 100%;
                height: auto;
                max-height: unset;
            }

            .yellow-btn {
                margin-right: 0;
            }
        }

        &::before {
            display: none;
        }
    }

    .timeline-header {
        padding: 4px 0 20px 0;
        border: none;
        line-height: 34px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        >span {
            margin-bottom: 8px;
            display: inline-flex;
            align-items: center;
        }

        .userimage {
            float: left;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            margin: -2px 12px -2px 0;
        }

        .username a {
            color: $primaryColor;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 14px;
            transition: color 0.3s;

            &:hover {
                color: $primaryContrast;
            }
        }

        .post-date {
            font-size: 12px;
            color: $mediumNeutral;
            position: relative;
            margin-left: 16px;

            >span:first-child {
                margin: 0 4px;
            }

            &::before {
                content: "•";
                position: absolute;
                left: -8px;
                line-height: 36px;
            }
        }
    }

    .timeline-new-post {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}

.sponsored-post-tag {
    font-size: 12px;
    line-height: 24px;
    padding: 0 8px;
    display: inline-block;
    border: 1px solid $primaryContrast;
    color: $primaryContrast;
    border-radius: 4px;
    height: 26px;
}

.status-document {
    font-size: 12px;
    line-height: 24px;
    padding: 0 8px;
    display: inline-block;
    border: 1px solid $primaryContrast;
    color: $primaryContrast;
    border-radius: 4px;
}

.modal-header .sponsored-post-tag {
    right: 60px;
}

.timeline-likes {
    margin-top: 25px;
    color: #a0a0a0;
    font-weight: 400;

    .stats-left {
        display: flex;
        align-items: center;

        .stats:first-child {
            margin-right: 20px;
        }

        .stats {
            a {
                margin-right: 4px;
                color: $primaryColor;
                transition: color 0.3s;

                &:hover {
                    color: $primaryContrast;
                }
            }

            .stats-total {
                color: $primaryColor;
            }
        }
    }
}

.timeline-comment-box {
    padding: 15px 20px;
}

.timeline-comment-list {
    display: flex;
    flex-flow: column;
    margin-top: 15px;
}

.timeline-comment {
    padding: 8px 8px 8px 12px;
    background: $clientColor;
    border-radius: 5px;
    position: relative;

    .user {
        margin-right: 12px;
    }

    .timeline-comment-user {
        font-size: 12px;
        color: $primaryColor;
        text-transform: uppercase;
    }

    p {
        margin: 0 25px 8px 0;
    }

    .btn-link {
        position: absolute;
        top: 8px;
        right: 8px;
    }
}

.timeline-comment:not(:last-child) {
    margin-bottom: 10px;
}

@media (max-width: 992px) {

    .wall-row {
        flex-direction: column-reverse;
        flex-wrap: nowrap;

        .profile-content {
            height: calc(75vh - 140px);
            padding: 20px;
        }
    }

    .announcer-card {
        background-position: 50% 0%;
        width: calc(100vw - 40px);
        height: 25vh;
        right: unset;
        position: initial;
        margin: 20px auto;

        .card-body {
            padding: 20px;
            overflow: hidden;
            max-width: 100%;
            display: flex;
            flex-direction: column;
        }

        .card-title {
            font-size: 16px;
        }

        .card-content {
            position: initial;
            width: 100%;
            height: 100%;

            .announcer-card-meta {
                margin-top: 0;

                .img-fluid {
                    max-height: 8vh;
                }

                h5 {
                    font-size: 16px;
                    margin-bottom: 8px;
                }

                >div:last-child {
                    margin-top: 8px !important;

                    >p:first-child {
                        font-size: 14px !important;
                        margin-bottom: 4px !important;
                    }
                }
            }
        }
    }

    .suggestion-wrapper {
        padding-top: 16px;

        >h4 {
            position: initial;
            width: 100%;
            text-align: left;
            font-size: 16px;
            color: $primaryColor;
            margin-left: 16px;
        }

        .react-multi-carousel-list {
            overflow: auto;
            max-width: 100%;

            .react-multiple-carousel__arrow {
                display: none;

                &::before {
                    display: none;
                }
            }
        }

    }
}

@media (max-width: 768px) {

    .wall-row {
        .profile-content {
            height: calc(70vh - 140px);
        }
    }

    .announcer-card {
        background-position: 50% 0%;
        height: 30vh;
    }
}

@media (max-width: 512px) {

    .wall-row {
        .profile-content {
            height: calc(70vh - 154px);
        }
    }
}