@import 'variables';

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(1, 0, 185, 0.8);
    }

    70% {
        -webkit-box-shadow: 0 0 0 28px rgba(204, 169, 44, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(1, 0, 185, 0.8);
        box-shadow: 0 0 0 0 rgba(1, 0, 185, 0.8);
    }

    70% {
        -moz-box-shadow: 0 0 0 28px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 14px rgba(204, 169, 44, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

.ant-btn-primary,
.rounded-corner.blue-btn,
.rounded-corner.yellow-btn {
    border-radius: 36px;
    padding: 8px 18px;
    display: inline-flex;
    align-items: center;
    box-shadow: $boxShadow;
    color: $clientColor;
    line-height: normal;
    margin: 0 6px;
    transition: all 0.3s;
    height: 36px;
    border: none;
}

.rounded-corner.blue-btn,
a.blue-btn:not([href]):not([tabindex]),
.ant-btn-primary {
    background: $clientColor  !important;
    color: $primaryColor;

    &:hover {
        color: $clientColor;
        background: $primaryColor  !important;
    }

    .d-flex.justify-content-center {
        justify-content: center;
        min-width: 100%;
        padding: 4px;
    }
}

.rounded-corner.yellow-btn {
    background: $primaryContrast  !important;

    &:hover {
        color: $primaryContrast;
        background: $clientColor  !important;
    }
}

.rounded-corner.yellow-btn .material-icons,
.rounded-corner.blue-btn .material-icons {
    font-size: 18px;
    margin-right: 8px;
}

.pulse {
    box-shadow: 0 0 0 rgba(1, 0, 185, 0.8);
    animation: pulse 1.7s infinite;

    &:hover {
        animation: none;
    }
}

.btn-link {
    border: none;
    color: $mediumNeutral;
    transition: color 0.3s;
    background: none !important;
    text-decoration: none;
    --bs-btn-focus-shadow-rgb: unset;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: $primaryColor  !important;
        background: none;
        box-shadow: none;
        outline: none;
    }
}

.btn-danger {
    box-shadow: $boxShadow;
    transition: all 0.3s;

    &:hover {
        box-shadow: $boxShadow;
        background: $clientColor  !important;
        color: $primaryContrast;
    }
}

.btn.btn-primary[disabled] {
    background: $mediumNeutral  !important;
    border-color: $mediumNeutral;
}

.ant-btn-default {
    color: $primaryColor;
    border-radius: 32px;

    &:hover,
    &:focus {
        background: $primaryColor;
        color: $clientColor;
        border-color: $primaryColor;
    }
}

.btn-with-icon {
    display: flex;
    align-items: center;
    gap: 8px;

    span+.anticon {
        margin: 0;
    }
}