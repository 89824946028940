@import 'variables';

.header.navbar-default,
.page-header-fixed #header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    padding: 0;
    height: auto;
    background: $clientColor;
    box-shadow: $boxShadow;
    max-width: 100vw;
    transition: all 0.3s;

    >.container-fluid {
        padding: 0 24px;
        transition: all 0.3s;

        .navbar-brand {
            height: 60px;
            font-size: 18px;
            color: $primaryColor;
            font-weight: 400;
            padding: 14px 0;
            width: fit-content;
            margin: 0 24px 0 0;

            img {
                margin-right: 10px;
            }
        }

        .navbar-toggler {
            padding: 8px;
            border-radius: 50px;
            background: $clientColor;
            border: 1px solid $clientColor;
            transition: all 0.3s;
            box-shadow: $boxShadow;
            white-space: nowrap;
            font-size: 14px;

            &:hover {
                border: 1px solid $primaryContrast;
            }
        }
    }

    .navbar-collapse {
        float: unset !important;
        height: auto;
        flex-flow: row wrap;
        width: 100%;
        max-width: 100%;
        align-items: flex-start;
        padding: 10px 0 20px 0;
        overflow: visible;
        transition: all 0.3s;

        >.navbar-nav:first-child {
            display: flex;

            a.nav-link {
                white-space: nowrap;
                font-weight: 500;
                color: $textColor;
                transition: color 0.3s;

                &:hover {
                    color: $primaryColor;
                }
            }

            @media (max-width: 1199px) {
                margin-bottom: 10px;
            }
        }

        >.navbar-nav:last-child {
            align-items: center;
            flex-flow: row nowrap;

            .navbar-user.btn.btn-primary {
                border: 0;
                box-shadow: none;
                padding: 0;
                height: 36px;
                width: 36px;
                border-radius: 36px;
                margin: 0 0 0 16px;
                border: 1px solid $mediumNeutral;
                overflow: hidden;
                background-color: $lightNeutral  !important;

                .dropdown-toggle {
                    margin: 0;
                    border: 0;
                    overflow: hidden;
                    background-color: $lightNeutral  !important;

                    img {
                        float: unset;
                        margin: 0;
                        object-fit: cover;
                    }
                }

                &::before,
                .dropdown-toggle::before {
                    display: none;
                }
            }

            >a.announcer-btn {
                display: block;
                padding: 6px 16px 6px 16px;
                border-radius: 50px;
                background: $clientColor;
                border: 1px solid $primaryColor;
                color: $primaryColor;
                font-weight: 500;
                font-size: 15px;
                margin-right: 16px;
                transition: all 0.3s;
                box-shadow: $boxShadow;
                white-space: nowrap;

                &:hover {
                    background: $primaryColor;
                    color: $clientColor;
                    text-decoration: none;
                }
            }

            >a.announcer-btn.active {
                background: $primaryContrast;
                border: 1px solid $primaryContrast;

                &:hover {
                    background: transparent;
                    color: $primaryContrast;
                }
            }
        }
    }

    .navbar-collapse.show {
        display: flex;
    }

    @media (min-width: 1200px) {
        height: 60px;

        .navbar-collapse {
            display: flex;
            height: 60px;
            width: calc(100vw - 200px);
            max-width: calc(100vw - 200px);
            align-items: center;
            padding: 0;
            flex-flow: row nowrap;
            justify-content: space-between;
        }
    }
}

.btn-primary.dropdown-toggle.notification-toggle {
    background: transparent !important;
    padding: 0;
    box-shadow: none;
    height: fit-content;
    width: fit-content;
    border: 0;
    outline: 0;

    &:active,
    &:focus,
    &:hover {
        outline: 0 !important;
        box-shadow: none !important;
        background: transparent !important;
        border: 0 !important;
    }

    &::before {
        display: none;
    }

    .dropdown-toggle.icon.notification-btn,
    .round-btn {
        height: 32px;
        width: 32px;
        min-width: 32px;
        border-radius: 50%;
        background-color: $clientColor;
        box-shadow: $boxShadow;
        position: relative;
        display: block;
        transition: background-color 0.3s;

        >i {
            position: absolute;
            top: 6px;
            left: 6.5px;
            color: $primaryColor;
            font-size: 20px;
            height: 20px;
            line-height: 20px;
            transition: color 0.3s;
        }

        >i.active {
            color: $primaryContrast;
        }

        >.label {
            position: absolute;
            right: -10px;
            top: -2px;
            height: 18px;
            width: 18px;
            font-size: 10px;
            line-height: 19px;
            background: $warning;
            border-radius: 50%;
            padding: 0;
        }

        &:hover {
            background-color: $primaryContrast;

            >i {
                color: $clientColor;
            }
        }

        &::before {
            display: none;
        }
    }
}

.notification-menu.dropdown-menu.show {
    max-width: 400px;
    max-height: 98vh;
    overflow-y: auto;
    overflow-x: hidden;

    .dropdown-item {
        display: flex;
        align-items: center;

        .media-left {
            height: 40px;
            width: 40px;
            border-radius: 40px;
            overflow: hidden;
            display: block;
            margin-right: 10px;

            img {
                width: auto;
                height: 100%;
            }
        }

        .media-body {
            width: unset;
            min-width: fit-content;

            h6 {
                color: $primaryColor;
                text-transform: uppercase;
                margin-bottom: 2px;
            }

            p {
                margin-bottom: 2px;
                font-size: 14px;
                color: $textColor;
            }

            >div:last-child {
                color: $textColor;
                opacity: 0.8;
            }
        }
    }

    @media (max-width: 1200px) {
        position: fixed !important;
        left: 150px;
        right: unset;
        top: 118px !important;
        max-height: calc(98vh - 118px);
    }

    @media (max-width: 464px) {
        right: 10px;
        top: 10px !important;
        max-height: calc(100vh - 40px);
    }
}