@import 'variables';

* {
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: $lightNeutral $clientColor;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-thumb {
    background: $lightNeutral;
    border-radius: 8px;
}

*::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
}