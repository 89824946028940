@import 'variables';

.nav-tabs {
    background: none;
    margin: 0 20px;

    .nav-link {
        background: rgba(19, 19, 19, 0.5);
        margin-top: 7px;
        border-radius: 0;
        border: none;
        color: $clientColor !important;
        font-weight: 300;

        &:hover {
            border: none;
            color: $primaryContrast !important;
            background: rgba(19, 19, 19, 0.5); 
        }
    }

    .nav-link.active {
        padding: 17px 15px 10px 15px;
        background: rgba(255, 255, 255, 0.6);
        border: none;
        border-radius: 8px 8px 0 0;
        margin-top: 0;
        color: $primaryColor !important;
        font-weight: 500;
    }

    li.nav-item:last-child .nav-link:not(.active) {
        border-radius: 0 5px 0 0;
    }

    li.nav-item:first-child .nav-link:not(.active) {
        border-radius: 5px 0 0 0;
    }
}