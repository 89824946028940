@import 'variables';

.footer {
    z-index: 8;
    padding-right: 24px;
    height: 40px;
    line-height: 18px;
    border-top: 1px solid $clientColor;
    color: $clientColor;
    font-weight: 500;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100vw;
    max-width: 100vw;
    margin: 0;
    background: rgba(1, 0, 185, 0.1);
    backdrop-filter: blur(2px);

    a {
        color: $clientColor;

        &:hover {
            color: $primaryContrast;
        }
    }

    span:not(:last-child) {
        a {
            margin-right: 12px;
        }
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }

    @media (max-width: 512px) {
        height: 54px;
        padding: 10px 20px;
    }
}